// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-codingasbaking-js": () => import("/opt/build/repo/src/pages/codingasbaking.js" /* webpackChunkName: "component---src-pages-codingasbaking-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("/opt/build/repo/src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-speeches-js": () => import("/opt/build/repo/src/pages/speeches.js" /* webpackChunkName: "component---src-pages-speeches-js" */),
  "component---src-pages-work-js": () => import("/opt/build/repo/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

